* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  overflow-x: hidden;
}
@font-face {
  font-family: Helvetica;
  src: url(../assets/fonts/Helvetica.ttf);

  font-family: HelveticaNeue;
  src: url(../assets/fonts/HelveticaNeueRegular.otf);

  font-family: visbycfmedium;
  font-weight: 500;
  src: url(../assets/fonts/visbycf-medium.otf);

  font-family: visbycf;
  font-weight: 400;
  src: url(../assets/fonts/visbycf-regular.otf);
}

@font-face {
  font-family: 'visbycf';
  src: url('../assets/fonts/FONTSPRINGDEMO-VisbyCFRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visbycfmedium';
  src: url('../assets/fonts/FONTSPRINGDEMO-VisbyCFMediumRegular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../assets/fonts/HelveticaNeue-Roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #111725;
}
a {
  display: inline-block;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

img {
  width: 100%;
}
.mdt-60 {
  margin-top: 60px;
}

.banner_alignment {
  align-items: center;
  justify-content: center;
}

.align-item-center {
  align-items: center;
}
.innovation_bg {
  background-image: url("../assets/images/innovation_background_img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 354px 50px;
  background-position: center center;
  min-height: 980px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.react-responsive-modal-modal {
  max-width: 816px !important;
  width: 100%;
  padding: 70px 58px;
}
.moda_wrapper {
  margin-left: 20px;
  margin-right: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.align-end {
  align-items: flex-end;
}
.no-wrape {
  flex-wrap: nowrap !important;
}
.blank_space {
  max-width: 112px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    width: 100%;
    max-width: 50%;
  }
  .col-md-4 {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
  }
  .col-md-8 {
    flex: 0 0 75%;
    max-width: 75%;
    width: 100%;
  }
  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    width: 100%;
  }
  .col-md-7 {
    flex: 0 0 58.3334%;
    max-width: 58.3334%;
    width: 100%;
  }
  .justify_between {
    justify-content: space-between;
  }
  .align_end {
    align-items: end;
  }
}

@media only screen and (min-width: 992px) {
  .teaminnerColumn_01 {
    padding-left: 35px !important;
    padding-right: 10px !important;
  }
  .teaminnerColumn_02 {
    padding-left: 10px !important;
    padding-right: 35px !important;
  }
  .col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    width: 100%;
  }
  .col-lg-7 {
    flex: 0 0 58.3334%;
    max-width: 58.3334%;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .innovation_bg {
    background-size: cover;
    min-height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .innovation_bg {
    padding: 202px 50px;
  }
  .teaminnerColumn_01 {
    padding-right: 5px !important;
  }
  .teaminnerColumn_02 {
    padding-left: 5px !important;
  }
  .react-responsive-modal-modal{
    max-width: 100% !important;
    padding: 15px;
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 767px) {
  .col-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .innovation_bg {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .teaminnerColumn_02 {
    padding-left: 15px !important;
  }
  .innovation_bg {
    padding: 202px 20px;
  }
  .mt-15 {
    margin-top: 0px;
  }
}
